import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

function BlogDetails(){
    const navigate = useNavigate();
    const params = useParams();    

    useEffect(()=>{
        if(params.id <= 0){
            navigate("/blogs");
        }    
        console.log(params.id);
    },[params.id, navigate])


    return (
        <div>
            <h1>Blog Details {params.id}</h1>
        </div>
    );
}


export default BlogDetails;