function About(){
    return (
        <div>
              <div className="jumbotron jumbotron-fluid text-center bg-jumbotron" style={{ backgroundPosition: '50% -98.5px' }}>
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-12">
                                <i className="icon icon-big color icon-book-read-streamline"></i>
                                <h1 className="display-6 text-white py-3">About Us</h1>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-12 card mt-5 d-flex">
                            <div className="col">ok</div>
                            <div className="col">Hi</div>
                        </div>
                    </div>
                </div>
        </div>
    );
}


export default About;