import { app_auth_delete, app_auth_post, app_auth_post_out } from "./ApiCalls";

const dashboard = () => {
  return app_auth_post("dashboard", {});
};

const logout = () => {
  return app_auth_post_out("oauth2/logout", {});
};

const addTopic = ({
  subject,
  topicName,
  description,
  subjectName,
  examName,
  studyMaterial,
}) => {
  const post_data = {
    subject: subject,
    topicName: topicName,
    description: description,
    subjectName: subjectName,
    examName: examName,
    studyMaterial: studyMaterial,
  };
  return app_auth_post("add-topic", post_data);
};

const updateTopicRecallType = (id, recallType) => {
  const post_data = {
    recallType: recallType,
  };
  return app_auth_post(`study-recall-update/${id}`, post_data);
};

const updateTopic = ({
  id,
  subject,
  topicName,
  description,
  subjectName,
  examName,
  studyMaterial,
}) => {
  const post_data = {
    id: id,
    subject: subject,
    topicName: topicName,
    description: description,
    subjectName: subjectName,
    examName: examName,
    studyMaterial: studyMaterial,
  };
  return app_auth_post("study-topics-update", post_data);
};

const topicList = (data) => {
  return app_auth_post("study-topics", data);
};

const deleteTopic = (id) => {
  return app_auth_delete(`study-topics/${id}`, {});
};

const topicMemorizedList = (data) => {
  return app_auth_post("study-topics-memorized", data);
};

const topicReviewList = (data) => {
  return app_auth_post("study-topics-review", data);
};

const topicReviewByID = (id) => {
  return app_auth_post(`study-topics-review/${id}`, {});
};

const topicByID = (id) => {
  return app_auth_post(`study-topics/${id}`, {});
};

const profile = () => {
  return app_auth_post(`profile`, {});
};

const profileUpdate = ({ firstName, lastName, mobile_no, interests }) => {
  const post_data = {
    firstName: firstName,
    lastName: lastName,
    mobile_no: mobile_no,
    interests: interests,
  };
  return app_auth_post("profile-update", post_data);
};

const changePassword = ({ oldPassword, newPassword }) => {
  const post_data = {
    oldPassword: oldPassword,
    newPassword: newPassword,
  };
  return app_auth_post("password-update", post_data);
};

export {
  logout,
  dashboard,
  addTopic,
  topicList,
  deleteTopic,
  updateTopicRecallType,
  topicMemorizedList,
  topicReviewList,
  topicByID,
  topicReviewByID,
  updateTopic,
  profile,
  profileUpdate,
  changePassword,
};
