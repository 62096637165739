/* eslint-disable */
import React, { useEffect, useState } from "react";
import {
  Container,
  Typography,
  Grid, 
  Card, 
  CardHeader,
  Avatar,
} from "@mui/material";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import TimelineIcon from "@mui/icons-material/Timeline";
import ScheduleIcon from "@mui/icons-material/Schedule";
import { dashboard } from "./services/AppService";
import { useNavigate } from "react-router-dom"; 
import Box from '@mui/material/Box'; 
import UsageGuide from "./UsageGuide";
import { Link } from 'react-router-dom';
const shapeCircleStyles = { borderRadius: '50%' };
const shapeStyles = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  p: 1,
  border: '1px solid grey',
  borderRadius: 2,
  width: '100px',
  height: '150px',
};
 
const rectangle = (
  <Box component="span" sx={shapeStyles}>    
    <Typography variant="body2" align="center">
      Topics
    </Typography>
  </Box>
);
const circle = (
  <Box component="span" sx={{ ...shapeStyles, ...shapeCircleStyles }} />
);

function AppHome() {
  const navigate = useNavigate();
  const [dashboardData, setDashboardData] = useState({
    totalTopics: 0,
    pendingRevision: 0,
    memorizedTopics: 0,
  });

  useEffect(() => {
    document.body.style.backgroundColor = '#F7F8FA';
    fetchDashboardData();
    // Optional: Cleanup by resetting color on unmount if needed
    return () => {
      document.body.style.backgroundColor = '';
    };
    
  }, []);

  const fetchDashboardData = async () => {
    try {
      const response = await dashboard();
      if (response.ok) {
        const data = await response.json();
        setDashboardData(data);
      } else {
        if (response.status === 401) {
          console.error("Unauthorized access - logging out user");
          navigate("/auth/logout");
        }
        console.error("Failed to fetch dashboard data");
      }
    } catch (error) {
      console.error("Error fetching dashboard data:", error);
    }
  };

  const iconStyles = {
    color: "#007acc",
    backgroundColor: "white",
  };

  return (
    <Container maxWidth="xl" sx={{ p: 2 }}>  
      <Grid container spacing={3} sx={{ mb: 10 }}>       
        <UsageGuide />
        <Grid item xs={12} sm={6} md={3}>
          <Card>
            <Link to="/app/topics" style={{ textDecoration: 'none' }}>  
            <CardHeader
              avatar={
                <Avatar style={iconStyles}>
                  <FormatListNumberedIcon />
                </Avatar>
              }
              title={
                <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
                  <Typography variant="subtitle1">Total Topics</Typography>
                  <Typography variant="h4" color="text.primary">
                    {dashboardData.totalTopics}
                  </Typography>
                </Box>
              }
            />   
            </Link>        
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Card>
            <Link to="/app/recalls" style={{ textDecoration: 'none' }}>  
            <CardHeader
              avatar={
                <Avatar style={iconStyles}>
                  <TimelineIcon />
                </Avatar>
              }
              title={
                <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
                  <Typography variant="subtitle1">Pending Recall</Typography>
                  <Typography variant="h4" color="text.primary">
                    {dashboardData.pendingRevision}
                  </Typography>
                </Box>
              }
            />
            </Link>   
            {/* <CardContent>
              <Typography variant="h4">
                {dashboardData.pendingRevision}
              </Typography>
            </CardContent> */}
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Card>
            <Link to="/app/memorized" style={{ textDecoration: 'none' }}>  
            <CardHeader
              avatar={
                <Avatar style={iconStyles}>
                  <ScheduleIcon />
                </Avatar>
              }
              title={
                <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
                  <Typography variant="subtitle1">Memorized Topics</Typography>
                  <Typography variant="h4" color="text.primary">
                    {dashboardData.memorizedTopics}
                  </Typography>
                </Box>
              }
            />
            </Link>   
            {/* <CardContent>
              <Typography variant="h4">
                {dashboardData.memorizedTopics}
              </Typography>
            </CardContent> */}
          </Card>
        </Grid>
      </Grid>     
    </Container>
  );
}

export default AppHome;
