import { Box, Card, 
  // CardContent, 
  Typography, Avatar, Stepper, Step, StepLabel } from '@mui/material';
import { EmojiObjects, AddCircle, Repeat } from '@mui/icons-material';

const steps = [
  {
    icon: <EmojiObjects style={{ color: '#fbc02d' }} />, // Lightbulb icon for "Study"
    title: <span style={{ color: '#1976d2', fontWeight: 'bold' }}>Study Topics</span>,
    description: <span style={{ color: '#555' }}>Study the topic until you achieve <strong>complete memorization</strong>.</span>,
  },
  {
    icon: <AddCircle style={{ color: '#4caf50' }} />, // Add icon for "Add Topic"
    title: <span style={{ color: '#1976d2', fontWeight: 'bold' }}>Add to App</span>,
    description: <span style={{ color: '#555' }}>Incorporate the topic into the app to <strong>monitor your progress</strong>.</span>,
  },
  {
    icon: <Repeat style={{ color: '#2196f3' }} />, // Repeat icon for "Revise"
    title: <span style={{ color: '#1976d2', fontWeight: 'bold' }}>Initial Revision</span>,
    description: (
      <div style={{ color: '#555' }}>
        <p><strong>1.</strong> When your memory shows <strong>40%</strong>, try to recall what you learned last.</p>
        <p><strong>2.</strong> Review the topic until you can <strong>remember everything</strong>.</p>
        <p><strong>3.</strong> <strong>Update the topic</strong> in the app once fully memorized.</p>
      </div>
    ),
  },
  {
    icon: <Repeat style={{ color: '#2196f3' }} />, // Repeat icon for "Subsequent Revision"
    title: <span style={{ color: '#1976d2', fontWeight: 'bold' }}>Subsequent Revision</span>,
    description: (
      <div style={{ color: '#555' }}>
        <p><strong>1.</strong> After some time, check if your memory drops back to <strong>40%</strong>.</p>
        <p><strong>2.</strong> Review the material again until you reach <strong>complete memorization</strong>.</p>
        <p><strong>3.</strong> <strong>Update the topic</strong> in the app once mastered.</p>
      </div>
    ),
  },
  {
    icon: <Repeat style={{ color: '#2196f3' }} />, // Repeat icon for "Reinforce Learning"
    title: <span style={{ color: '#1976d2', fontWeight: 'bold' }}>Reinforce Learning</span>,
    description: (
      <div style={{ color: '#555' }}>
        <p><strong>1.</strong> Continue to <strong>repeat the revision process regularly</strong>.</p>
        <p><strong>2.</strong> This will help <strong>reinforce your understanding</strong> and retention.</p>
      </div>
    ),
  },
];



// Motivational message at the end of the steps
const motivationMessage = "Unlock your true potential with consistent practice! Stay focused and let the app guide you to mastery.";

const UsageGuide = () => {
  return (
    <Box sx={{ maxWidth: 600, mx: 'auto', mt: 4 }}>
      <Typography variant="h4" align="center" gutterBottom>
        How to Use the App
      </Typography>
      <Stepper orientation="vertical" sx={{ mt: 2 }}>
        {steps.map((step, index) => (
          <Step key={index} active completed>
            <StepLabel icon={step.icon}>
              <Card variant="outlined" sx={{ mt: 2, display: 'flex', alignItems: 'center', p: 2 }}>
                <Avatar sx={{ bgcolor: 'background.paper', mr: 2 }}>{step.icon}</Avatar>
                <Box>
                  <Typography variant="h6" fontWeight="bold">
                    {step.title}
                  </Typography>
                  <Typography variant="body1" color="text.secondary">
                    {step.description}
                  </Typography>
                </Box>
              </Card>
            </StepLabel>
          </Step>
        ))}
      </Stepper>

      {/* Motivational Message */}
      {/* <Card sx={{ mt: 4, bgcolor: 'secondary.light', p: 3, textAlign: 'center' }}>
        <Typography variant="h6" fontWeight="bold" color="primary">
          {motivationMessage}
        </Typography>
      </Card> */}
    </Box>
  );
};

export default UsageGuide;
