import React from 'react'; 

function ResponsiveImage({ alt, srcJpeg, srcWebp, srcAvif, className, width, height }) {
    return (
      <picture className={className}>
        <source srcSet={srcAvif} type="image/avif" />
        <source srcSet={srcWebp} type="image/webp" />
        <img 
          src={srcJpeg} 
          alt={alt} 
          loading="lazy" 
          width={width || "100%"} 
          height={height || "auto"}  
        />
      
      </picture>
    );
  }
  
  export default ResponsiveImage;
  
   
