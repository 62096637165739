import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Toast from "../../component/ToastService";
import { StatusType } from "../../constants/Status";
import { signup } from "./services/LoginService";
import "./Signup.css"; // Import your custom CSS for styling
import { set_logged_data } from "../../service/Configuration";
import GoogleIcon from "@mui/icons-material/Google";

function Signup() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    confirm_password: "",
  });

  const onInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    try {
      if (!formData.email) {
        Toast(StatusType.ERROR, "Please enter email address.");
        return;
      }

      if (!formData.password) {
        Toast(StatusType.ERROR, "Please enter password.");
        return;
      }

      if (!formData.confirm_password) {
        Toast(StatusType.ERROR, "Please enter confirm password.");
        return;
      }

      if (formData.password !== formData.confirm_password) {
        Toast(StatusType.ERROR, "Password and confirm password do not match.");
        return;
      }

      const response = await signup(formData);
      const responseData = await response.json();

      if (response.ok) {
        if (responseData) {
          set_logged_data(responseData.data.token);
          Toast(StatusType.SUCCESS, responseData.message);
          navigate("/app");
        }
      } else {
        if (responseData) {
          Toast(StatusType.ERROR, responseData.message);
        }
      }
    } catch (error) {
      Toast(StatusType.ERROR, error.message);
    }
  };

  return (
    <div className="login-container">
    <div className="row vh-100">
      {/* Left Section with Background Image */}
      <div
        className="col-md-6 d-none d-md-flex justify-content-center align-items-center"
        style={{
          background: 'linear-gradient(135deg, rgba(60, 179, 113, 0.8), rgba(2, 2, 36, 0.8))',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      >
        {/* Overlay for image */}
        <div className="overlay d-flex justify-content-center align-items-center text-white">
          <h2 className="text-center text-white" style={{ background: 'rgba(0, 0, 0, 0.5)', padding: '10px 20px', borderRadius: '10px', color: 'white' }}>
            Join US!
          </h2>
        </div>
      </div>
  
      {/* Right Section with Form */}
      <div className="col-md-6 d-flex justify-content-center align-items-center">
        <div className="login-form-container p-4" style={{ boxShadow: '0 8px 30px rgba(0, 0, 0, 0.1)', borderRadius: '15px', backgroundColor: '#fff' }}>
          <form className="login-form" onSubmit={onSubmit} style={{ width: '302px'}}>
            <h3 className="text-center mb-3" style={{ fontWeight: 'bold', color: '#333' }}>Sign Up</h3>
            
            {/* Email Input */}
            <div className="mb-3">
              <label htmlFor="email" className="form-label">
                Email Address
              </label>
              <input
                type="email"
                id="email"
                name="email"
                className="form-control"
                placeholder="Enter email address"
                onChange={onInputChange}
                style={{ borderRadius: '10px' }}
              />
              
            </div>
            
            {/* Password Input */}
            <div className="mb-3">
              <label htmlFor="password" className="form-label">
                Password
              </label>
              <input
                type="password"
                id="password"
                name="password"
                className="form-control"
                placeholder="Enter password"
                onChange={onInputChange}
                style={{ borderRadius: '10px' }}
              />
            </div>

            <div className="mb-3">
              <label htmlFor="password" className="form-label">
                Confirm Password
              </label>
              <input
                type="password"
                id="password"
                name="confirm_password"
                className="form-control"
                placeholder="Enter confirm password"
                onChange={onInputChange}
                style={{ borderRadius: '10px' }}
              />
            </div>             
            
            {/* Sign In Button */}
            <button
              type="submit"
              className="btn btn-primary btn-block mb-3 w-100"
              style={{ backgroundColor: '#4A90E2', border: 'none', borderRadius: '10px', padding: '10px' }}
            >
              Sign Up
            </button>
            
            {/* OAuth Section */}
            <div className="text-center mt-2">
              <p className="mb-2">Or sign up with</p>
              <div className="oauth-buttons d-flex justify-content-center">
              <a
                    href={`${process.env.REACT_APP_BASE_PATH}/oauth2/authorization/google`}
                    className="btn btn-outline-secondary mx-2"
                    style={{
                      borderRadius: "10px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <GoogleIcon style={{ marginRight: "8px" }} />{" "}
                    {/* Google Icon */}
                    Continue with Google
                  </a>
              </div>
            </div>
            
            {/* Register Link */}
            <div className="text-center mt-4">
              <p className="mb-1">
                Already a member?{' '}
                <Link to="/auth/login" className="text-decoration-none">
                  Login
                </Link>
              </p>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  );
}

export default Signup;
