import "../assets/styles/bootstrap.min.css";
import "../assets/styles/style.css";

import BrandLogoJpeg from "../assets/images/logo2.png";
import BrandLogoWebp from "../assets/images/logo2.webp";
import BrandLogoAvif from "../assets/images/logo2.avif";
import { Link } from "react-router-dom";
import ResponsiveImage from "../../../component/ResponsiveImage";

export default function Logo() {
  return (
    <Link to="/" className="navbar-brand d-flex align-items-center">
      {/* <img
        src={BrandLogo}
        className="brand-logo"
        style={{ maxWidth: "34px", height: "auto" }}
        // alt="Rise to Mastery"
      /> */}
        <ResponsiveImage
          alt="Rise to Mastery"
          srcJpeg={BrandLogoJpeg}
          srcWebp={BrandLogoWebp}
          srcAvif={BrandLogoAvif}
          width="34"
          height="34"
          className=""
        />
      <span className="ps-2">Rise to Mastery</span>
    </Link>
  );
}
