import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { clear_logged_data } from "../../service/Configuration";
import Toast from "../../component/ToastService";
import { StatusType } from "../../constants/Status";

const Logout = () => {
  const navigate = useNavigate();

  useEffect(() => {
    clear_logged_data();
    Toast(StatusType.SUCCESS, "Logout successfully");
    navigate("/auth/login");
  }, [navigate]);

  return null;
};

export default Logout;
