import { toast } from 'react-toastify';
import { StatusType } from '../constants/Status';

const Toast = (type, msg) => {
    if(type){
        switch(type){
            case StatusType.SUCCESS:
                toast.success(msg, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    className: 'custom-toast', // Apply custom class
                    });
                break;
            case StatusType.ERROR:
                toast.error(msg, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    className: 'custom-toast', // Apply custom class
                    });
                break;
            case StatusType.INFO:
                    toast.info(msg, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                        className: 'custom-toast', // Apply custom class
                        });
                    break;
            default:
                toast.info(msg, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    className: 'custom-toast', // Apply custom class
                    });
                break;
        }
    }
   
}

export default Toast;