import { decrypt, encrypt } from "./CryptoService";
import moment from "moment";

const USER_KEY = "cj3ka4ns5kc6jn7as7k8jc513654lkc4naklsc";

export const set_local_storage = (key, val) => {
  val && localStorage.setItem(key, encrypt(val));
};

export const get_local_storage = (key) => {
  const val = localStorage.getItem(key);
  return val ? decrypt(val) : null;
};

export const clear_local_storage = (key) => {
  localStorage.setItem(key, "");
  localStorage.removeItem(key);
};

export const set_logged_data = (data) => set_local_storage(USER_KEY, data);
export const get_logged_data = () => get_local_storage(USER_KEY);
export const clear_logged_data = () => clear_local_storage(USER_KEY);

export const formattedDate = (myDate) => {
  return myDate
    ? moment(myDate).format("DD MMM, yyyy") +
        " <br/>" +
        moment(myDate).format("hh:mm:ss A")
    : "-";
};

export const formattedDate1 = (myDate) => {
  return myDate
    ? moment(myDate).format("DD MMM, yyyy") +
        " " +
        moment(myDate).format("hh:mm:ss A")
    : "-";
};
