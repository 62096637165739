import { Outlet } from "react-router-dom";
import React, { useEffect } from 'react';
import HeaderLogin from "./HeaderLogin";
import { Helmet } from 'react-helmet';

function LayoutLogin() {
    useEffect(() => {          
        document.body.style.overflow = 'hidden';
        return () => {
          document.body.style.overflow = ''; // Reset on unmount
        };
      }, []);
    
    return(
        <>
            <Helmet>
                <link rel="stylesheet" type="text/css" href="../assets/styles/bootstrap.min.css" />
                <link rel="stylesheet" type="text/css" href="../assets/styles/style.css" />
                <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.7.1/jquery.min.js" type="text/javascript" />            
                <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.3.2/dist/js/bootstrap.bundle.min.js" type="text/javascript" />            
            </Helmet>
            <HeaderLogin />
            <Outlet />
        </>
    )    
}

export default LayoutLogin;