import CryptoJS from "crypto-js";
import forge from "node-forge";

const PUBLIC_KEY = process.env.REACT_APP_PUBLIC_KEY;
const SECRET_KEY = process.env.REACT_APP_SECRET_KEY;

export const encrypt = (input_text) =>
  input_text && CryptoJS.AES.encrypt(input_text, SECRET_KEY).toString();

export const decrypt = (input_text) =>
  input_text &&
  CryptoJS.AES.decrypt(input_text, SECRET_KEY).toString(CryptoJS.enc.Utf8);

 
export const encryptData = (data) => {
    try {
      console.log("Starting encryption process...");
  
      if (!data) {
        console.error("No data provided for encryption.");
        throw new Error("No data provided for encryption.");
      }
  
      if (!PUBLIC_KEY) {
        console.error("Public key is not available.");
        throw new Error("Public key is not available.");
      }
  
      // Trim any extra newlines or spaces
      const trimmedPublicKey = PUBLIC_KEY.replace(/\\n/g, '\n').trim();
      const publicKey = forge.pki.publicKeyFromPem(trimmedPublicKey);
  
      if (!publicKey) {
        console.error("Public key is invalid or not provided.");
        throw new Error("Public key is invalid or not provided.");
      }
  
      console.log("Public key loaded successfully.");
  
      // Generate a random symmetric key
      const symmetricKey = forge.random.getBytesSync(16); // AES-128 key
      const iv = forge.random.getBytesSync(16); // AES initialization vector
  
      // Encrypt the data with AES
      const cipher = forge.cipher.createCipher('AES-CBC', symmetricKey);
      cipher.start({ iv });
      cipher.update(forge.util.createBuffer(data));
      cipher.finish();
      const encryptedData = cipher.output.getBytes();
  
      console.log("Data encrypted successfully with AES.");
  
      // Encrypt the symmetric key with RSA
      const encryptedSymmetricKey = publicKey.encrypt(symmetricKey, 'RSA-OAEP', {
        md: forge.md.sha256.create(),
        mgf1: { md: forge.md.sha1.create() },
      });
  
      console.log("Symmetric key encrypted successfully with RSA.");
  
      // Return both encrypted symmetric key and encrypted data
      return {
        encryptedSymmetricKey: forge.util.encode64(encryptedSymmetricKey),
        iv: forge.util.encode64(iv),
        encryptedData: forge.util.encode64(encryptedData),
      };
  
    } catch (error) {
      console.error("Encryption error:", error.message);
      return null;
    }
  };
  
