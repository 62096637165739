import { home_post } from "./ApiCalls"

const contactus = ({name, email, subject, message}) => {
    const postdata = {
        name : name,
        email_id : email,
        subject : subject,
        message : message            
    }
    return home_post("/contactus", postdata);
}

const forgot_password_check = (id) => {
    const postdata = {       
        token : id          
    }
    return home_post("/forgot-password-check", postdata);
}


const forgot_password_update = ({token, password}) => {
    const postdata = {       
        token : token,
        password: password          
    }
    return home_post("/forgot-password-update", postdata);
}

export {contactus, forgot_password_check, forgot_password_update}

