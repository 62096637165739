// import { Link } from 'react-router-dom';
// import BrandLogo from '../assets/images/Rapid learn-logos_transparent1.png';

function Footer() {
    return (
        <footer className="tm-footer pt-3 bg-dark text-white">
            {/* <div className="tm-footer-topside tm-padding-section">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 col-md-6 col-12 mt-3">
                            <div className="single-widget widget-info">
                                <Link to={'/'} className="widget-info-logo text-decoration-none">
                                    <img src={BrandLogo} alt="Rapid Learn" width="250px" style={{ backgroundColor: '#f8f9fa' }} />
                                </Link>
                                <p className="mt-3 text-white">Learn better with us using spaced repetition.</p>
                                <ul className="d-flex d-none">
                                    <li><Link to={'/'} className="text-white"><i className="ion-social-facebook" /></Link></li>
                                    <li><Link to={'/'} className="text-white"><i className="ion-social-twitter" /></Link></li>
                                    <li><Link to={'/'} className="text-white"><i className="ion-social-instagram-outline" /></Link></li>
                                    <li><Link to={'/'} className="text-white"><i className="ion-social-pinterest-outline" /></Link></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-12 mt-3">
                           
                        </div>
                        <div className="col-lg-3 col-md-6 col-12 mt-3">
                            
                        </div>
                        <div className="col-lg-3 col-md-6 col-12 mt-3 text-white">
                            <div className="single-widget widget-hours">
                                <h4 className="widget-title fs-4">Contact Us</h4>
                                <p className='mt-3 text-white'>Feel at ease reaching out to us.</p>
                                <ul>
                                    <li><span>Mail ID</span>: <a href="mailto:rapidlearn@gmail.com" className="text-white">rapidlearn@gmail.com</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
            <div className="tm-footer-bottomside cst-copyright">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-12">
                            <p className="tm-footer-copyright text-center   text-white">
                                {/* Copyright © */}
                                 Rise to Mastery | 2024
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
