import "../assets/styles/bootstrap.min.css";
import "../assets/styles/style.css";

// import BrandLogo from "../assets/images/logo2.png";
import { Link } from "react-router-dom";
import Logo from "./Logo";
import { get_logged_data } from "../../../service/Configuration";
import { useEffect, useState } from "react";


function Header() {

   const [isLogin, setIsLogin] = useState(false);
 
  useEffect(() => {
    const token = get_logged_data();
    if(token){
      setIsLogin(true);
    }

  }, []);

  return (
    <header className="sticky-top bg-white shadow-sm">
      <nav className="navbar navbar-expand-lg navbar-light container p-2">
      <Logo />
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon" />
        </button>
        <div className="collapse navbar-collapse" id="navbarNav">
                    
            
            {
              !isLogin &&
              <ul className="navbar-nav ms-auto mb-2 mb-lg-0 ps-4">
              <li className="nav-item">
                <Link to="/auth/login" className="btn btn-out-sec db mt-3 ms-3 mt-lg-0">
                  Login
                </Link>
              </li>
            <li className="nav-item">
              <Link to="/auth/sign-up" className="btn btn-sec ms-3 mt-2 mt-lg-0">
                Sign Up
              </Link>
            </li>
            </ul>
            }
            {
              isLogin &&
              <ul className="navbar-nav ms-auto mb-2 mb-lg-0 ps-4">
               
              <li className="nav-item">
                <Link to="/app" className="btn btn-sec ms-3 mt-2 mt-lg-0">
                  Open Mastery
                </Link>
              </li>
              </ul>
            }
         
        </div>
      </nav>
    </header>
  );
}

export default Header;
