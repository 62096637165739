import { useState } from "react";
import Toast from "../../component/ToastService";
import { StatusType } from "../../constants/Status";
import { contactus } from "./services/ApiService";
import contactusimages from "../home/assets/images/cu.jpg";
import "./Contact.css";
import {
  TextField,
  Button,
  Card,
  Grid,
  Typography,
  Container,
} from "@mui/material";
import { Person, Email, Subject, Message } from "@mui/icons-material"; // Material icons

function Contact() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });

  const onInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    try {
      if (!formData.name) {
        Toast(StatusType.ERROR, "Please enter name.");
        return;
      }

      if (!formData.email) {
        Toast(StatusType.ERROR, "Please enter email address.");
        return;
      }

      if (!formData.subject) {
        Toast(StatusType.ERROR, "Please enter subject.");
        return;
      }

      if (!formData.message) {
        Toast(StatusType.ERROR, "Please enter message.");
        return;
      }

      const response = await contactus(formData);
      const responseData = await response.json();
      if (response.ok) {
        if (responseData) {
          Toast(StatusType.SUCCESS, responseData.message);
          return;
        }
      } else {
        if (responseData) {
          Toast(StatusType.ERROR, responseData.message);
          return;
        }
      }
    } catch (error) {
      Toast(StatusType.ERROR, error.message);
      return;
    }
  };

  return (
    <Container maxWidth={false} className="contact-page">
      <div
        className="header-section text-center py-5"
        style={{
          position: "relative",
          background:
            "linear-gradient(135deg, rgba(60, 179, 113, 0.8), rgba(2, 2, 36, 0.8)) center center / cover",
          padding: "40px",
        }}
      >
        <Typography
          variant="h3"
          component="h1"
          className="display-4 font-weight-bold"
          style={{
            color: "#FFFFFF", // White text for better contrast
            fontSize: "3.5rem",
            textShadow: "2px 2px 4px rgba(0, 0, 0, 0.3)", // Maintain subtle shadow for depth
          }}
        >
          Get in Touch
        </Typography>
        <Typography
          variant="subtitle1"
          className="lead"
          style={{
            color: "#FFFFFF", // Light gray text for contrast against the background
            fontSize: "1.2rem",
          }}
        >
          We are here to help. Fill out the form and we will get back to you
          soon!
        </Typography>
      </div>  

      <Grid container spacing={4} alignItems="center">
        <Grid
          item
          lg={3}
          md={12}
          sm={12}
          xs={12}
          style={{ width: "auto", margin: "auto" }}
        >
          <img
            src={contactusimages}
            alt="Contact Us"
            className="img-fluid rounded shadow-lg mt-5"
            style={{ width: "100%" }} // Ensure the image takes full width
          />
        </Grid>
        <Grid
          item
          lg={3}
          md={12}
          sm={12}
          xs={12}
          style={{ width: "auto", margin: "auto" }}
        >
          <Card
            className="contact-card shadow-lg p-4"
            style={{ width: "100%" }}
          >
            <Typography
              variant="h4"
              className="text-center mb-4 font-weight-bold"
            >
              Send us a Message
            </Typography>
            <form onSubmit={onSubmit} className="form-container">
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    label="Your Name"
                    name="name"
                    value={formData.name}
                    onChange={onInputChange}
                    InputProps={{
                      startAdornment: <Person color="primary" />,
                    }}
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    label="Your Email"
                    name="email"
                    value={formData.email}
                    onChange={onInputChange}
                    InputProps={{
                      startAdornment: <Email color="primary" />,
                    }}
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    label="Subject"
                    name="subject"
                    value={formData.subject}
                    onChange={onInputChange}
                    InputProps={{
                      startAdornment: <Subject color="primary" />,
                    }}
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    label="Your Message"
                    name="message"
                    value={formData.message}
                    onChange={onInputChange}
                    multiline
                    rows={4}
                    InputProps={{
                      startAdornment: <Message color="primary" />,
                    }}
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                    size="large"
                  >
                    Send Message
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
}

export default Contact;
