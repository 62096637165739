import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { set_logged_data } from "../../service/Configuration";
import Toast from "../../component/ToastService";
import { StatusType } from "../../constants/Status";


function Google(){

    const { id } = useParams();
    const navigate = useNavigate();
    useEffect(()=>{
        console.log("id: ", id);
        if(id){
            const decodedToken = atob(id); 
            console.log("Decoded Token: ", decodedToken);
            set_logged_data(decodedToken);
            Toast(StatusType.SUCCESS, "Login Success.");
            navigate("/app");
        }else{
            navigate("/auth/login");
        }
    }, [id]);

    
    return (
        <div className="container my-5">          
        </div>
    );
}


export default Google;