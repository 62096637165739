import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; 
import { Link } from "react-router-dom"; 

import heroJpeg from "./assets/images/hero1.jpeg";
import heroWebp from "./assets/images/hero1.webp";
import heroAvif from "./assets/images/hero1.avif";

import profJpeg from "./assets/images/prof.png";
import profWebp from "./assets/images/prof.webp";
import profAvif from "./assets/images/prof.avif";

import succJpeg from "./assets/images/succ.png";
import succWebp from "./assets/images/succ.webp";
import succAvif from "./assets/images/succ.avif";

import timetableJpeg from "./assets/images/timetable.png";
import timetableWebp from "./assets/images/timetable.webp";
import timetableAvif from "./assets/images/timetable.avif";

import memorizeJpeg from "./assets/images/memorize.png";
import memorizeWebp from "./assets/images/memorize.webp";
import memorizeAvif from "./assets/images/memorize.avif";

import remindersJpeg from "./assets/images/reminders.png";
import remindersWebp from "./assets/images/reminders.webp";
import remindersAvif from "./assets/images/reminders.avif";
import ResponsiveImage from "../../component/ResponsiveImage";
 
function HeroSection() {
  return (
    <div id="tm-about-area" className="tm-about-area tm-padding-section py-5 bg-white">
      <div className="container ">
        {/* About Section */}
        <div className="row align-items-center ">
          <div className="col-lg-6 order-lg-0 order-1">
            <div className="">
              <h1 className="mb-4 sec-feature-title">
                Master Your Learning with Spaced Repetition
              </h1>
              <p className="">
                Effortlessly retain knowledge for exams and professional
                success.
              </p>
              <div className="tm-about-buttons mt-4">
                <Link to="/auth/sign-up" className="btn btn-sec mt-2 mt-lg-0">
                
                  Start your Journey
                </Link>
              </div>
            </div>
          </div>
          <div className="col-lg-6 mb-4 mb-lg-0 order-lg-1 order-0">
            <div className="tm-videobox text-center"> 
              <ResponsiveImage
                alt="Hero Image"
                srcJpeg={heroJpeg}
                srcWebp={heroWebp}
                srcAvif={heroAvif}
                width="610"
                height="377"
                className="img-fluid"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function Home() {
  return (
    <>
      <main className="bg-white">
        <HeroSection /> 

        <div
          id="tm-features-area"
          className="tm-feature-area tm-section bg-white py-5"
        >
          <div className="container">
            <h2>How Spaced Repetition Boosts Your Learning</h2>
            <div className="row text-center mt-5">
              <div className="col-lg-4 col-md-6 col-12 mb-4">
                {/* <img
                  src={timetable}
                  className="feature-icon w-25 mx-auto p-3"
                  alt="Feature Icon 1"
                /> */}
                 <ResponsiveImage
                    alt="timetable"
                    srcJpeg={timetableJpeg}
                    srcWebp={timetableWebp}
                    srcAvif={timetableAvif}
                    width="55"
                    height="55"
                    className="feature-icon mx-auto"
                  />
                <h5 className="feature-title fw-bold">Spaced Learning</h5>
                <p className="feature-text">
                  Revisit key concepts at increasing intervals to solidify your
                  understanding and retain information longer.
                </p>
              </div>
              <div className="col-lg-4 col-md-6 col-12 mb-4">             
                <ResponsiveImage
                    alt="reminders"
                    srcJpeg={remindersJpeg}
                    srcWebp={remindersJpeg}
                    srcAvif={remindersAvif}
                    width="55"
                    height="55"
                    className="feature-icon mx-auto"
                  />
                <h5 className="feature-title fw-bold">Tailored Schedules</h5>
                <p className="feature-text">
                  Personalized revision plans based on your learning progress.
                </p>
              </div>
              <div className="col-lg-4 col-md-6 col-12 mb-4">
                {/* <img
                  src={memorize}
                  className="feature-icon w-25 mx-auto p-3"
                  alt="Feature Icon 3"
                /> */}
                 <ResponsiveImage
                    alt="memorize"
                    srcJpeg={memorizeJpeg}
                    srcWebp={memorizeWebp}
                    srcAvif={memorizeAvif}
                    width="55"
                    height="55"
                    className="feature-icon mx-auto"
                  />
                <h5 className="feature-title fw-bold">Proven Success</h5>
                <p className="feature-text">
                  Backed by research, used by top professionals and students
                  preparing for competitive exams.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div
          id="tm-about-area"
          className="tm-about-area tm-padding-section py-5 section-3 bg-white"
        >
          <div className="container ">
            {/* About Section */}
            <div className="row align-items-center ">
              <div className="col-lg-6 mb-4 mb-lg-0">
                <div className="tm-videobox text-center pe-5">
                  {/* <img src={prof} className="img-fluid" alt="Videobox" /> */}
                  <ResponsiveImage
                    alt="section 1"
                    srcJpeg={profJpeg}
                    srcWebp={profWebp}
                    srcAvif={profAvif}
                    width="490"
                    height="490"
                    className="img-fluid"
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="ps-lg-5">
                  <h2 className="mb-4 sec-feature-title">
                    Built for Your Success
                  </h2>
                  <div className="row mt-5">
                    <div className="col-lg-6 mb-4">
                      <div className="p-4 border border-primary rounded shadow-sm">
                        <p className="lead text-primary">
                          <strong>Professionals</strong>
                          <br />
                          <br />
                          <span className="txt-dark" >
                            {" "}
                            Stay updated on industry knowledge and
                            certifications to advance your career without
                            feeling overwhelmed.
                          </span>
                        </p>
                      </div>
                    </div>
                    <div className="col-lg-6 mb-4">
                      <div className="p-4 border border-success rounded shadow-sm">
                        <p className="lead text-success">
                          <strong>Students</strong>
                          <br />
                          <br />
                          <span className="txt-dark">
                            {" "}
                            Prepare efficiently for competitive exams like GRE,
                            GMAT, and UPSC with smart learning strategies.
                          </span>
                        </p>
                      </div>
                    </div>                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          id="tm-features-area"
          className="tm-feature-area tm-section bg-light py-5"
        >
          <div className="container">
            <h2>Features to Supercharge Your Learning</h2>
            <div className="row text-center mt-5">
              <div className="col-lg-4 col-md-6 col-12 mb-4">
                {/* <img
                  src={timetable}
                  className="feature-icon w-25 mx-auto p-3"
                  alt="Feature Icon 1"
                /> */}
                <ResponsiveImage
                    alt="timetable"
                    srcJpeg={timetableJpeg}
                    srcWebp={timetableWebp}
                    srcAvif={timetableAvif}
                     width="55"
                    height="55"
                    className="feature-icon mx-auto"
                  />
                <h5 className="feature-title fw-bold">
                  Adaptive Learning Algorithms
                </h5>
                <p className="feature-text">
                  Adjusts the difficulty and frequency of reviews based on your
                  performance.
                </p>
              </div>
              <div className="col-lg-4 col-md-6 col-12 mb-4">
                {/* <img
                  src={reminders}
                  className="feature-icon w-25 mx-auto p-3"
                  alt="Feature Icon 2"
                /> */}
                 <ResponsiveImage
                    alt="reminders"
                    srcJpeg={remindersJpeg}
                    srcWebp={remindersWebp}
                    srcAvif={remindersAvif}
                     width="55"
                    height="55"
                    className="feature-icon mx-auto"
                  />
                <h5 className="feature-title fw-bold">Progress Tracking</h5>
                <p className="feature-text">
                  Detailed analytics to show where you’re improving and where
                  you need more focus.
                </p>
              </div>
              <div className="col-lg-4 col-md-6 col-12 mb-4">
                {/* <img
                  src={memorize}
                  className="feature-icon mx-auto"
                  alt="Feature Icon 3"
                /> */}
                <ResponsiveImage
                    alt="memorize"
                    srcJpeg={memorizeJpeg}
                    srcWebp={memorizeWebp}
                    srcAvif={memorizeAvif}
                     width="55"
                    height="55"
                    className="feature-icon mx-auto"
                  />
                <h5 className="feature-title fw-bold">
                  Customizable Learning Paths
                </h5>
                <p className="feature-text">
                  Personalize your study sessions based on subjects, goals, or
                  exams.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div
          id="tm-about-area"
          className="tm-about-area tm-padding-section py-5 section-4"
        >
          <div className="container ">
            {/* About Section */}
            <div className="row align-items-center ">
              <div className="col-lg-6 mb-4 mb-lg-0 pe-5">
                <div className="tm-videobox text-center">
                  {/* <img src={succ} className="img-fluid" alt="Videobox" /> */}
                  <ResponsiveImage
                    alt="section 2"
                    srcJpeg={succJpeg}
                    srcWebp={succWebp}
                    srcAvif={succAvif}
                    className="img-fluid"
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="ms-lg-5 ps-lg-5">
                  <h2 className="mb-4 sec-feature-title">
                    Start Retaining Knowledge Today
                  </h2>
                  <div className="tm-whyus-blocks">
                    {/* Reason 1 */}
                    <div className="tm-whyus-block d-flex align-items-center mb-4">
                      {/* <div className="rounded-circle bg-danger text-center text-white d-flex align-items-center justify-content-center me-3 shadow-lg">
                        <span className="fs-4 px-2 py-1">1</span>
                      </div> */}
                      <div>
                        <h5>
                          "Join thousands of learners already using spaced
                          repetition to ace exams and advance careers."
                        </h5>
                      </div>
                    </div>
                  </div>
                  <div className="tm-about-buttons mt-4">
                    <Link
                      to={"/auth/sign-up"}
                      className="btn btn-sec px-4 py-2"
                    >
                      Sign Up Now
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container bg-teal py-5">
          <h2 className="text-center mb-4">Frequently Asked Questions</h2>
          <div className="accordion" id="faqAccordion">
            <div className="accordion-item">
              <h2 className="accordion-header" id="faqHeading1">
                <button
                  className="accordion-button"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#faqCollapse1"
                  aria-expanded="true"
                  aria-controls="faqCollapse1"
                >
                  1. How does spaced repetition work?
                </button>
              </h2>
              <div
                id="faqCollapse1"
                className="accordion-collapse collapse show"
                aria-labelledby="faqHeading1"
                data-bs-parent="#faqAccordion"
              >
                <div className="accordion-body">
                  Spaced repetition helps you remember information by scheduling
                  reviews at increasing intervals, reinforcing your memory when
                  you need it most.
                </div>
              </div>
            </div>

            <div className="accordion-item">
              <h2 className="accordion-header" id="faqHeading2">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#faqCollapse2"
                  aria-expanded="false"
                  aria-controls="faqCollapse2"
                >
                  2. Is this platform suitable for both professionals and
                  students?
                </button>
              </h2>
              <div
                id="faqCollapse2"
                className="accordion-collapse collapse"
                aria-labelledby="faqHeading2"
                data-bs-parent="#faqAccordion"
              >
                <div className="accordion-body">
                  Yes! Whether you're preparing for exams or advancing your
                  career, our system adapts to your learning needs and goals.
                </div>
              </div>
            </div>

            <div className="accordion-item">
              <h2 className="accordion-header" id="faqHeading3">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#faqCollapse3"
                  aria-expanded="false"
                  aria-controls="faqCollapse3"
                >
                  3. Can I track my learning progress?
                </button>
              </h2>
              <div
                id="faqCollapse3"
                className="accordion-collapse collapse"
                aria-labelledby="faqHeading3"
                data-bs-parent="#faqAccordion"
              >
                <div className="accordion-body">
                  Absolutely. Our platform provides detailed insights into your
                  study habits, progress, and areas that need more focus.
                </div>
              </div>
            </div>

            <div className="accordion-item">
              <h2 className="accordion-header" id="faqHeading4">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#faqCollapse4"
                  aria-expanded="false"
                  aria-controls="faqCollapse4"
                >
                  4. Can I use this platform for multiple subjects?
                </button>
              </h2>
              <div
                id="faqCollapse4"
                className="accordion-collapse collapse"
                aria-labelledby="faqHeading4"
                data-bs-parent="#faqAccordion"
              >
                <div className="accordion-body">
                  Yes! You can create custom study paths for any subject or
                  exam, making it easy to manage various learning goals.
                </div>
              </div>
            </div>

            <div className="accordion-item">
              <h2 className="accordion-header" id="faqHeading5">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#faqCollapse5"
                  aria-expanded="false"
                  aria-controls="faqCollapse5"
                >
                  5. What devices can I use to access the platform?
                </button>
              </h2>
              <div
                id="faqCollapse5"
                className="accordion-collapse collapse"
                aria-labelledby="faqHeading5"
                data-bs-parent="#faqAccordion"
              >
                <div className="accordion-body">
                  Our platform is accessible across mobile, tablet, and desktop,
                  so you can learn anytime, anywhere.
                </div>
              </div>
            </div>
          </div>
        </div>

      </main>
    </>
  );
}

export default Home;
