import React, { useState, useEffect } from "react";
import {
  Container,
  Card,
  CardContent,
  Typography,
  Grid,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Divider,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { topicByID, updateTopicRecallType } from "./services/AppService";
import Toast from "../../component/ToastService";
import { StatusType } from "../../constants/Status";
import { formattedDate1 } from "../../service/Configuration";

const recallTypes = [
  { value: 1, label: "Easy" },
  { value: 2, label: "Medium" },
  { value: 3, label: "Hard" },
];

const StudyTopicDetails = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [topicDetails, setTopicDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [currentRecallType, setCurrentRecallType] = useState(-1);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await topicByID(id);
        const responseData = await response.json();
        if (response.ok) {
          if (responseData.status === "OK") {
            setTopicDetails(responseData.data);
          } else {
            Toast(StatusType.ERROR, responseData.message);
          }
        } else {
          Toast(StatusType.ERROR, responseData.message);
        }
      } catch (error) {
        Toast(StatusType.ERROR, "An error occurred while fetching data.");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [id]);

  const handleRecallTypeChange = (event) => {
    setCurrentRecallType(event.target.value);
  };

  const handleUpdate = async () => {
    try {
      if (currentRecallType <= 0) {
        Toast(StatusType.ERROR, "Please select current recall type.");
        return;
      }
      const response = await updateTopicRecallType(id, currentRecallType);
      const responseData = await response.json();
      if (response.ok) {
        if (responseData.status === "OK") {
          Toast(StatusType.SUCCESS, "Recall type updated successfully.");
          navigate("/app/topics");
        } else {
          Toast(StatusType.ERROR, responseData.message);
        }
      } else {
        Toast(StatusType.ERROR, responseData.message);
      }
    } catch (error) {
      Toast(StatusType.ERROR, "An error occurred while updating data.");
    }
  };

  const handleBack = () => {
    navigate("/app/topics");
  };

  if (loading) {
    return <Typography>Loading...</Typography>;
  }

  if (!topicDetails) {
    return <Typography>No topic found</Typography>;
  }

  return (
    <Container maxWidth="lg" className="w-100">
      <Card variant="outlined" className="w-100 p-4">
        <CardContent>
          <Typography variant="h6" className="mb-4" gutterBottom>
            Topic Details
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Typography variant="body1">
                <strong>Topic Name:</strong> {topicDetails.topicName || "N/A"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body1">
                <strong>Subject:</strong> {topicDetails.subjectName || "N/A"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body1">
                <strong>Exam:</strong> {topicDetails.examName || "N/A"}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1">
                <strong>Topic Description:</strong>
                <br />
                {topicDetails.description || "N/A"}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1">
                <strong>Study Material:</strong>
                <br />
                {topicDetails.studyMaterial || "N/A"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body1">
                <strong>Retention Percentage:</strong>
                <br />
                {topicDetails.retentionPercentage || "N/A"}%
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body1">
                <strong>Number of Recall:</strong>
                <br />
                {topicDetails.noOfRevision}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body1">
                <strong>Last Recall Date:</strong>
                <br />
                {formattedDate1(topicDetails.lastReviewDate) || "N/A"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body1">
                <strong>Last Recall Type:</strong>
                <br />
                {topicDetails.lastRecallType || "N/A"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} className="mt-4">
              <Divider />
            </Grid>
            <Grid item xs={12} sm={6} className="mt-4">
              <FormControl fullWidth variant="outlined">
                <InputLabel>Current Recall Type</InputLabel>
                <Select
                  value={currentRecallType}
                  onChange={handleRecallTypeChange}
                  label="Current Recall Type"
                >
                  {recallTypes.map((type) => (
                    <MenuItem key={type.value} value={type.value}>
                      {type.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Button
            className="mt-4"
            variant="contained"
            color="secondary"
            onClick={handleUpdate}
          >
            Update
          </Button>
          <Button
            className="mt-4 ms-2"
            variant="contained"
            color="primary"
            onClick={handleBack}
          >
            Cancel
          </Button>
        </CardContent>
      </Card>
    </Container>
  );
};

export default StudyTopicDetails;
