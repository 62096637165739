import { get_logged_data } from "../../../service/Configuration";
const baseUrl = process.env.REACT_APP_BACKEND_URL;
const home_get = (endpoint, data = {}) => {
  return fetch(`${baseUrl}${endpoint}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

const home_post = (endpoint, data) => {
  return fetch(`${baseUrl}${endpoint}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
};

const app_auth_post = (endpoint, data) => {
  const token = get_logged_data();
  return fetch(`${baseUrl}/api/${endpoint}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data),
  });
};

const app_auth_post_out = (endpoint, data) => {
  const token = get_logged_data();
  return fetch(`${baseUrl}/${endpoint}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data),
  });
};

const app_auth_delete = (endpoint, data) => {
  const token = get_logged_data();
  return fetch(`${baseUrl}/api/${endpoint}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data),
  });
};

export { home_get, home_post, app_auth_post, app_auth_post_out, app_auth_delete };
