// import { useEffect } from "react";
import "../assets/styles/bootstrap.min.css";
import "../assets/styles/style.css";
import Logo from "../layout/Logo";

function HeaderLogin() {

  // useEffect(() => {
  //   // Disable scrolling on both body and html when component mounts
  //   document.body.style.overflow = "hidden";
  //   document.documentElement.style.overflow = "hidden";

  //   // Re-enable scrolling when component unmounts
  //   return () => {
  //     document.body.style.overflow = "auto";
  //     document.documentElement.style.overflow = "auto";
  //   };
  // }, []);

  return (
    <header className="sticky-top bg-white shadow-sm">
      <nav className="navbar navbar-expand-lg navbar-light container p-2">
      <Logo />       
      </nav>
    </header>
  );
}

export default HeaderLogin;
