import { home_basic_auth, home_post } from "./ApiCalls";

const login = ({email, password}) => {
    const post_data = {     
        username : email,
        password : password
    }
    return home_post("/login", post_data);
}

const oauth_google = (oauth) => {
    const post_data = {
        credential: oauth.credential,
        clientId: oauth.clientId 
      }
    return home_post("/oauth2/success", post_data);
}

const token = ({email, password}) => {
    const post_data = {     
        username : email,
        password : password
    }
    return home_basic_auth("/login", post_data);
}

const signup = ({email, password}) => {
    const post_data = {     
        email : email,
        password : password
    }
    return home_post("/signup", post_data);
}

const forgot_password = ({email}) => {
    const post_data = {     
        email_id : email       
    }
    return home_post("/forgot-password", post_data);
}

export { forgot_password, login, oauth_google, signup, token };
